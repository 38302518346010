import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Crm from '@/Models/Crm'
import LegalDocument from '@/Models/LegalDocument'
import TypeDocumentResidency from '@/Models/TypeDocumentResidency'
import http from '@/services/http'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class FormDocumentLegal extends Vue {
  @Prop({ required: false, default: null })
  objMember!: Crm
  @Prop({ required: false, default: null })
  objRow!: LegalDocument
  @Prop({ required: false, default: null })
  isAction!: string
  selected!: any
  objLegalDocument: LegalDocument = new LegalDocument()
  loading = false
  isFileEdit = false
  isDocumentEdit = false

  documentStatus = Helpers.documentStatus()

  listTypeDocumentResidency: Array<TypeDocumentResidency> = []
  get is_login(): boolean {
    return authModule.isAuthenticated
  }

  async mounted() {
    await this.getTypeDocumentResidency()
    if (this.objRow && this.isAction == 'edit') {
      this.$set(this, 'objLegalDocument', this.objRow)
    }
  }
  async confirmation() {
    const document = this.document(this.objLegalDocument)
    switch (this.isAction) {
      case 'add':
        await this.createLegalDocument(document)
        break
      case 'edit':
        await this.updateLegalDocument(document)
        break
      default:
        console.log('ups')
        break
    }
  }
  async createLegalDocument(objLegalDocument: any) {
    try {
      await http.post(`/api/legal/arcr_legal_documents/`, objLegalDocument)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error) {
      console.error(error)
      this.loading = false
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  async updateLegalDocument(objLegalDocument: any) {
    console.log(this.objLegalDocument)
    try {
      await http.put(
        `/api/legal/arcr_legal_documents/${this.objRow.id}/`,
        objLegalDocument,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      console.log(error)
    }
  }
  async getTypeDocumentResidency() {
    try {
      const res = await http.get(`api/residency/document_types/`)
      if (!res.data) throw new Error(res.data)
      this.listTypeDocumentResidency = res.data.map((obj: any) => ({
        ...obj,
      }))
    } catch (error) {
      console.error(error)
      this.$buefy.toast.open({
        message: `Ups!, ha ocurrido un error`,
        type: 'is-danger',
      })
    }
  }
  document(objDocument: LegalDocument) {
    const formData = new FormData()
    const document = {
      document_type: objDocument.document_type,
      date_presented: Helpers.dateFormat_db(objDocument.date_presented),
      comment: objDocument.comment,
      status: objDocument.document_status,
      legal_id: menuModule.id_param,
    }
    formData.append('document', JSON.stringify(document))
    if (this.isFileEdit) {
      formData.append('file', objDocument.upload)
    }
    return formData
  }
  fileChange() {
    if (this.objLegalDocument.upload.name) {
      this.isFileEdit = true
    }
  }
  fileDocument() {
    if (this.objLegalDocument) {
      this.isDocumentEdit = true
    }
  }
  close() {
    this.objLegalDocument = new LegalDocument()
    this.$emit('cleanForm')
  }
}
