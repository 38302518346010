export default class LegalDocument {
  id!: number
  document_type!: number
  date_presented!: Date
  document_status!: string
  comment!: string
  fileUrl!: string
  upload!: File
  ccss!: number
}
